<template>
  <div class="app-veges">
    <div v-if="!state" class="title">
      <p>ביגוד רוסמן</p>
    </div>

    <div v-if="!state" class="main-menu-options">

      <Button
        label="הזמנה חדשה"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-success"
        @click="state='הזמנה חדשה'"
      />
      <Button
        v-if="user_role>=99"
        label="הזמנות"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-warning"
        @click="showManagerOrdersDialog=!showManagerOrdersDialog"
      />
      <Button
        v-if="user_role>=99"
        label="קטלוג"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-warning"
        @click="showCatalogDialog=!showCatalogDialog"
      />

      <Button
        v-if="user_role>=25"
        label="ההזמנות שלי"
        style="width:260px; height:100px; margin:5px; font-size: 2rem;"
        class="p-button-help"
        @click="show_my_orders=!show_my_orders"
      />
      
       <!-- <input type="file" @change="handle_import_from_csv"> -->
    </div>

    <NewOrderBigud @close="state=null" v-if="state=='הזמנה חדשה'"/>


  

    <Dialog
      header="קטלוג/מלאי"
      v-model:visible="showCatalogDialog"
      :modal="true"
      position="top"
    >
      <Catalog />
    </Dialog>

    <Dialog
      header="ניהול הזמנות"
      v-model:visible="showManagerOrdersDialog"
      :modal="true"
      position="top"
    >
      <OrdersMangerBigud />
    </Dialog>

    <Dialog
      header="הזמנות שלי"
      v-model:visible="show_my_orders"
      :modal="true"
      position="top"
    >
      <MyOrdersBigud />
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref } from "vue";
import Dialog from "primevue/dialog";
import NewOrderBigud from './components/NewOrderBigud.vue'
import Catalog from './components/Catalog.vue'
import OrdersMangerBigud from './components/OrdersMangerBigud.vue'
import MyOrdersBigud from './components/MyOrdersBigud.vue'
import { handle_back_button_on_browser } from '../../../Methods/Back_button_browser'
import store from '../../../store';
import XLSX from "xlsx";
import { projectFirestore } from '../../../firebase/config';

export default {
  components: {Dialog,NewOrderBigud,Catalog,OrdersMangerBigud,MyOrdersBigud},
  setup() {
    const {state} = handle_back_button_on_browser()
    const user_role = ref(computed(()=>{
            return store.getters.role
    }))
    const showManagerOrdersDialog = ref(false)
    const showCatalogDialog = ref(false)
    const show_my_orders = ref(false)
   

    const handle_import_from_csv = (e)=>{
      var file = e.target.files[0];
      if (!file) return;
      var FR = new FileReader();
        FR.onload = async function(e) {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, {type: 'array'});
          var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          
          // header: 1 instructs xlsx to create an 'array of arrays'
          var result = XLSX.utils.sheet_to_json(firstSheet, { header: 2 });
          
          // data preview
        for(const data of result){
          // data.selectedRole = data.selectedRole.split(',')
          await save_data_in_db(data)
          console.log(data);
        }
          
        };
        FR.readAsArrayBuffer(file);
    }

    const save_data_in_db=async(data)=>{
      await projectFirestore.collection('Applications').doc('wH3Q9T5lhhJ0PsdpBN1J').collection('Products').doc(data.barkod)
      .set(data,{merge:true})
    }
 
    return {
        state,
        showCatalogDialog,
        showManagerOrdersDialog,
        show_my_orders,
        user_role,
        handle_import_from_csv
      };
  }
};
</script>

<style scoped>
.app-veges {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  overflow-y: auto;
}
.main-menu-options {
  width: 350px;
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.choose-branch {
  width: 100%;
  height: 80vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.manager-panel {
  width: 100%;
  height: 80vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.catalog-manager {
  width: 80vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
}
.add-new-item {
  display: flex;
  flex-direction: column;
  height: 600px;
  gap: 10px;
  width: 100%;
}
.search-bar {
  width: 100%;
  height: 60px;
  margin-bottom: 0.5rem;
}
.product-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 57vh;
  overflow: hidden;
  overflow-y: auto;
  gap: 15px;
  border-radius: 3px;
}
.product-line {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
}
.new-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}
.map {
  text-align: center;
  width: 100%;
  display: flex;
  gap: 5px;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.orders-summery {
  width: 80vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.orders-summery-line {
  width: 100%;
  border: 1px solid rgb(175, 166, 166);
}
.product-headers {
  width: 100%;
  display: flex;
  font-weight: 500;
  text-align: center;
}
.product-id {
  font-size: 1.2rem;
}
.connect-item-dialog {
  width: 90vw;
  height: 70vh;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
}
.connect-item-right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
.connect-item-left-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
.product-connect-line {
  width: 100%;
  cursor: pointer;
  margin-bottom: 0.5rem;
  background-color: var(--bg-primary);
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.title-connect {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  background-color: rgb(5, 104, 5);
  color: white;
}
.price-line {
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .product-id {
    font-size: 10px;
  }
}
</style>
