<template>
  <div class="only-for-desktop">
      <div class="wrapper">
          <h2 style="text-align:center;">מסך זה תואם לגרסת מחשב בלבד</h2>
          <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fno-smartphones_375x375.png?alt=media&token=23692ff2-f6c9-42ad-a74e-b0f5ed183a1f">
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .only-for-desktop{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff;
    }
    .only-for-desktop .wrapper{
        width: 100%;
        max-width: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    h2{
        text-shadow: 1px 1px 0 #333,
        -1px -1px 0 #333,
        1px -1px 0 #333,
        -1px 1px 0 #333,
        2px 2px 5px rgba(0,0,0,0.65);
    }
</style>